import i18n from '@/plugins/i18n';

export const DynamicFieldType = {
  TEXT: 'text',
  TEXTAREA: 'textarea',
  CHECKBOX: 'checkbox',
  SELECT: 'select',
  MULTISELECT: 'multiselect',
  DATE: 'date',
  TIME: 'time',
  UPLOAD: 'upload',
};

export function getDynamicFieldTypeList() {
  return Object.values(DynamicFieldType).map(x => ({
    value: x,
    text: i18n.t(`DynamicFieldType.${x}`),
  }));
}
