export default [
  {
    path: '/admin/coffee-break',
    name: 'admin.coffee-break',
    redirect: { name: 'admin.coffee-break.orders' },
    components: {
      default: () =>
        import(/* webpackChunkName: "admin-cb" */ '@/views/admin/coffee-break/RoutePage'),
      tabs: () => import(/* webpackChunkName: "admin-cb" */ '@/views/admin/coffee-break/RouteTabs'),
    },
    meta: { isAuth: true },
    children: [
      {
        path: 'orders',
        name: 'admin.coffee-break.orders',
        components: {
          default: () =>
            import(
              /* webpackChunkName: "admin-cb" */ '@/views/admin/coffee-break/orders/OrdersPage'
            ),
          search: () =>
            import(
              /* webpackChunkName: "admin-cb" */ '@/views/admin/coffee-break/orders/OrderSearch'
            ),
        },
        meta: { isAuth: true },
      },
      {
        path: 'stock-transactions',
        name: 'admin.coffee-break.stock-transactions',
        component: () =>
          import(
            /* webpackChunkName: "admin-cb" */ '@/views/admin/coffee-break/transactions/StockTransactionsPage.vue'
          ),
        meta: { isAuth: true },
      },
      {
        path: 'menus',
        name: 'admin.coffee-break.menus',
        component: () =>
          import(
            /* webpackChunkName: "admin-cb" */ '@/views/admin/coffee-break/menus/MenusPage.vue'
          ),
        meta: { isAuth: true },
      },
      {
        path: 'products',
        name: 'admin.coffee-break.products',
        component: () =>
          import(
            /* webpackChunkName: "admin-cb" */ '@/views/admin/coffee-break/products/ProductsPage.vue'
          ),
        meta: { isAuth: true },
      },
      {
        path: 'stock-list',
        name: 'admin.coffee-break.stock-list',
        component: () =>
          import(
            /* webpackChunkName: "admin-cb" */ '@/views/admin/coffee-break/stock-list/StockListPage.vue'
          ),
        meta: { isAuth: true },
      },
      {
        path: 'stock-balance',
        name: 'admin.coffee-break.stock-balance',
        component: () =>
          import(
            /* webpackChunkName: "admin-cb" */ '@/views/admin/coffee-break/stock-balance/StockBalancePage.vue'
          ),
        meta: { isAuth: true },
      },
    ],
  },
  {
    path: '/admin/coffee-break/orders/new',
    name: 'admin.coffee-break.orders.new',
    component: () =>
      import(/* webpackChunkName: "admin-cb" */ '@/views/admin/coffee-break/orders/OrderNewPage'),
    meta: { isAuth: true, backBtn: true },
  },
  {
    path: '/admin/coffee-break/orders/:id(\\d+)/edit',
    name: 'admin.coffee-break.orders.edit',
    component: () =>
      import(/* webpackChunkName: "admin-cb" */ '@/views/admin/coffee-break/orders/OrderEditPage'),
    props: route => ({ id: parseInt(route.params.id, 10) }),
    meta: { isAuth: true, backBtn: true },
  },
  {
    path: '/admin/coffee-break/orders/:id(\\d+)',
    name: 'admin.coffee-break.orders.view',
    component: () =>
      import(/* webpackChunkName: "admin-cb" */ '@/views/admin/coffee-break/orders/OrderViewPage'),
    props: route => ({ id: parseInt(route.params.id, 10) }),
    meta: { isAuth: true, backBtn: true },
  },
];
