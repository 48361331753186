import i18n from '@/plugins/i18n';
import bookingApi from '@/api/bookingApi';

const initialState = () => ({
  rooms: [],
});

const state = initialState();

const getters = {
  rooms: state => state.rooms,
  getRoomById: state => id => state.rooms.find(x => x.id === id),
};

const actions = {
  async fetchRooms({ commit }) {
    return bookingApi.fetchRoomList().then(res => commit('ROOMS', res.data));
  },

  async createServiceReservation({ dispatch }, payload) {
    const res = await bookingApi.createServiceReservation(payload);
    dispatch('snackbar/notify', i18n.t('Заявка на бронирование создана'), { root: true });
    return res;
  },
};

const mutations = {
  ROOMS(state, payload) {
    state.rooms = payload;
  },

  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach(key => {
      state[key] = newState[key];
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
