import i18n from '@/plugins/i18n';
import { stStatus } from '@/constants/stStatus';
import { PaymentStatus } from '@/constants/payment';

export default function orderStatus(serviceTicket) {
  const isNewWithPayment = serviceTicket.status === stStatus.NEW && serviceTicket.paymentStatus;
  const isCanceledFailed =
    serviceTicket.status === stStatus.CANCELED &&
    [PaymentStatus.FAILED, PaymentStatus.ERROR].includes(serviceTicket.paymentStatus);
  const isCanceledRefunded =
    serviceTicket.status === stStatus.CANCELED &&
    serviceTicket.paymentStatus === PaymentStatus.REFUNDED;

  if (isNewWithPayment || isCanceledFailed || isCanceledRefunded) {
    return (
      i18n.t(`ServiceTicketStatus.${serviceTicket.status}`) +
      '. ' +
      i18n.t(`PaymentStatus.${serviceTicket.paymentStatus}`)
    );
  }

  if (
    serviceTicket.status === stStatus.CANCELED &&
    serviceTicket.paymentStatus === PaymentStatus.PURCHASED
  ) {
    return (
      i18n.t(`ServiceTicketStatus.${serviceTicket.status}`) + '. ' + i18n.t('Выполняется возврат')
    );
  }

  return i18n.t(`ServiceTicketStatus.${serviceTicket.status}`);
}
