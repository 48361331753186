import http from './http';

const baseUrl = (process.env.VUE_APP_API_PROFILE || '').replace(/\/$/g, '');

const profileApi = {
  company: {
    list: params => http.get(`${baseUrl}/company`, { params }),
    find: ({ id }) => http.get(`${baseUrl}/company/${id}`),
    create: data => http.post(`${baseUrl}/company`, data),
    update: ({ id, ...data }) => http.put(`${baseUrl}/company/${id}`, data),
    delete: ({ id }) => http.delete(`${baseUrl}/company/${id}`),
  },

  employee: {
    list: params => http.get(`${baseUrl}/employee`, { params }),
    find: ({ id }) => http.get(`${baseUrl}/employee/${id}`),
  },

  memberRequest: {
    list: params => http.get(`${baseUrl}/member-request`, { params }),
    create: data => http.post(`${baseUrl}/member-request`, data),
    update: ({ id, ...data }) => http.patch(`${baseUrl}/member-request/${id}`, data),
    delete: ({ id }) => http.delete(`${baseUrl}/member-request/${id}`),
  },

  notification: {
    list: params => http.get(`${baseUrl}/notification`, { params }),
    stats: () => http.get(`${baseUrl}/notification/stats`, { errorHandle: false }),
    seenAll: () => http.patch(`${baseUrl}/notification/seen`),
    deleteAll: () => http.delete(`${baseUrl}/notification`),
    seen: ({ id }) => http.patch(`${baseUrl}/notification/${id}/seen`),
    unseen: ({ id }) => http.patch(`${baseUrl}/notification/${id}/unseen`),
    delete: ({ id }) => http.delete(`${baseUrl}/notification/${id}`),
  },

  profile: {
    list: params => http.get(`${baseUrl}/profile`, { params }),
    find: ({ id }) => http.get(`${baseUrl}/profile/${id}`),
  },

  my: {
    company: {
      list: params => http.get(`${baseUrl}/company/my`, { params }),
    },

    companyOperation: {
      list: params => http.get(`${baseUrl}/company/operation`, { params }),
    },

    employee: {
      list: params => http.get(`${baseUrl}/my/employee`, { params }),
      find: ({ id }) => http.get(`${baseUrl}/my/employee/${id}`),
      update: ({ id, ...data }) => http.patch(`${baseUrl}/my/employee/${id}`, data),
    },

    externalCompany: {
      list: params => http.get(`${baseUrl}/external-company/my`, { params }),
      create: data => http.post(`${baseUrl}/external-company`, data),
      update: ({ id, ...data }) => http.put(`${baseUrl}/external-company/${id}`, data),
      delete: ({ id }) => http.delete(`${baseUrl}/external-company/${id}`),
    },

    profile: {
      fetch: () => http.get(`${baseUrl}/my-profile`, { errorHandle: false }),
      create: data => http.post(`${baseUrl}/my-profile`, data),
      update: (id, data) => http.patch(`${baseUrl}/my-profile`, data),
    },

    eraseProfile: data => http.post(`${baseUrl}/erase-profile`, data),
  },

  admin: {
    company: {
      list: params => http.get(`${baseUrl}/admin/company`, { params }),
      find: ({ id }) => http.get(`${baseUrl}/admin/company/${id}`),
      create: data => http.post(`${baseUrl}/admin/company`, data),
      update: ({ id, ...data }) => http.patch(`${baseUrl}/admin/company/${id}`, data),
      delete: ({ id }) => http.delete(`${baseUrl}/admin/company/${id}`),
      listByOperation: params => http.get(`${baseUrl}/admin/company/operation`, { params }),
      allSuppliers: params => http.get(`${baseUrl}/admin/all-suppliers`, { params }),
      mySuppliers: params => http.get(`${baseUrl}/admin/my/suppliers`, { params }),
      allCompanies: params => http.get(`${baseUrl}/admin/all-company`, { params }),
    },

    employee: {
      list: params => http.get(`${baseUrl}/admin/employee`, { params }),
      find: ({ id }) => http.get(`${baseUrl}/admin/employee/${id}`),
      create: data => http.post(`${baseUrl}/admin/employee`, data),
      update: ({ id, ...data }) => http.patch(`${baseUrl}/admin/employee/${id}`, data),
      delete: ({ id }) => http.delete(`${baseUrl}/admin/employee/${id}`),
    },

    memberRequest: {
      list: params => http.get(`${baseUrl}/admin/member-request`, { params }),
      update: ({ id, ...data }) => http.patch(`${baseUrl}/admin/member-request/${id}`, data),
      stats: () => http.get(`${baseUrl}/admin/member-request/stat`),
    },

    notificationJob: {
      list: data =>
        http.post(`${baseUrl}/admin/notification-job-search`, data, {
          params: { page: data.page, pageSize: data.pageSize },
        }),
      find: ({ id }) => http.get(`${baseUrl}/admin/notification-job/${id}`),
      create: data => http.post(`${baseUrl}/admin/notification-job`, data),
      update: ({ id, ...data }) => http.patch(`${baseUrl}/admin/notification-job/${id}`, data),
      delete: ({ id }) => http.delete(`${baseUrl}/admin/notification-job/${id}`),
    },

    profile: {
      list: params => http.get(`${baseUrl}/admin/profile`, { params }),
      find: ({ id }) => http.get(`${baseUrl}/admin/profile/${id}`),
      create: ({ userId, ...data }) => http.post(`${baseUrl}/admin/profile/${userId}`, data),
      update: ({ id, ...data }) => http.patch(`${baseUrl}/admin/profile/${id}`, data),
      delete: ({ id }) => http.delete(`${baseUrl}/admin/profile/${id}`),
      addToLocation: ({ id, ...data }) =>
        http.patch(`${baseUrl}/admin/profile/${id}/location`, data),
    },
  },

  saas: {
    company: {
      list: params => http.get(`${baseUrl}/saas/company`, { params }),
      find: ({ id }) => http.get(`${baseUrl}/saas/company/${id}`),
      create: data => http.post(`${baseUrl}/saas/company`, data),
      update: ({ id, ...data }) => http.patch(`${baseUrl}/saas/company/${id}`, data),
      delete: ({ id }) => http.delete(`${baseUrl}/saas/company/${id}`),
    },

    profile: {
      list: params => http.get(`${baseUrl}/saas/profile`, { params }),
      find: ({ id }) => http.get(`${baseUrl}/saas/profile/${id}`),
      create: ({ userId, ...data }) => http.post(`${baseUrl}/saas/profile/${userId}`, data),
      update: ({ id, ...data }) => http.patch(`${baseUrl}/saas/profile/${id}`, data),
      delete: ({ id }) => http.delete(`${baseUrl}/saas/profile/${id}`),
      addToLocation: ({ id, locationId, ...data }) =>
        http.patch(`${baseUrl}/saas/profile/${id}/location/${locationId}`, data),
    },
  },
};

export default profileApi;
