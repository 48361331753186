import { makeSimpleList } from '@/store/factory/simpleList';
import { ServiceType } from '@/constants/service';

export default makeSimpleList(
  {
    name: 'user',
    dataModel: 'db/serviceTickets',
    params: {
      status: 'new,confirmed,active',
      serviceType: [ServiceType.BOOKING, ServiceType.MASSAGE].join(','),
      limit: 100,
    },
  },
  {
    getters: {
      actualItems: (state, getters) => {
        const result = [...getters.items];
        result.sort((a, b) => Math.min(...a.reservation.slots) - Math.min(...b.reservation.slots));
        return result;
      },
    },
  }
);
