<template>
  <v-dialog v-if="notification" :value="true" width="600" persistent>
    <v-card elevation="8">
      <v-card-title class="text-h2 primary--text">
        {{ notification.data.title }}
      </v-card-title>
      <v-card-text class="text-body-1">
        <EditorContent :content="notification.data.announcement" />
      </v-card-text>
      <v-card-actions class="justify-space-between">
        <BtnSecondary large @click="onClose">
          {{ $t('Закрыть') }}
        </BtnSecondary>
        <BtnPrimary v-if="notification.data.message" large @click="onMoreDetails">
          {{ $t('Подробнее') }}
        </BtnPrimary>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import BtnSecondary from '@/components/ui/BtnSecondary';
import BtnPrimary from '@/components/ui/BtnPrimary';
import EditorContent from '@/components/ui/EditorContent';

export default {
  name: 'ImportantNotifications',

  components: {
    EditorContent,
    BtnPrimary,
    BtnSecondary,
  },

  computed: {
    ...mapGetters('importantNotificationList', ['items']),

    notification() {
      return this.items[0];
    },
  },

  mounted() {
    setTimeout(() => {
      this.fetchItems();
    }, 1000);
  },

  methods: {
    ...mapActions('importantNotificationList', ['fetchItems']),

    ...mapActions('db/notifications', ['seen']),

    onClose() {
      this.seen({ id: this.notification.id });
    },

    async onMoreDetails() {
      const id = this.notification.id;
      return this.seen({ id: id }).then(() => {
        this.$router.push({ name: 'home.notifications', query: { showId: id } });
      });
    },
  },
};
</script>
