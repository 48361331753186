const profile = [
  {
    path: '/profile',
    name: 'profile',
    redirect: { name: 'profile.personal' },
    components: {
      default: () => import(/* webpackChunkName: "profile" */ '@/views/profile/ProfilePage'),
      tabs: () => import(/* webpackChunkName: "profile" */ '@/views/profile/ProfileTabs'),
    },
    meta: { isAuth: true },
    children: [
      {
        path: '/profile/personal',
        name: 'profile.personal',
        component: () =>
          import(/* webpackChunkName: "profile" */ '@/views/profile/personal/PersonalPage'),
        meta: { isAuth: true },
      },
    ],
  },
];

export default [
  ...require('@/router/routes/common/aboutApp').default,
  ...require('@/router/routes/common/auth').default,
  ...profile,
  ...require('@/router/routes/common/support').default,
];
