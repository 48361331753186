import { OfferType } from '@/constants/offer';

export const RoleType = {
  SAAS_CONTRACT: OfferType.SAAS_CONTRACT,
  RENT_OFFICE: OfferType.RENT_OFFICE,
  ADMIN_CONTRACT: OfferType.ADMIN_CONTRACT,
  EMPLOYMENT_CONTRACT: OfferType.EMPLOYMENT_CONTRACT,
  RENT_WORKPLACE: OfferType.RENT_WORKPLACE,
  PUBLIC_OFFER: OfferType.PUBLIC_OFFER,
  PARTNER_CONTRACT: OfferType.PARTNER_CONTRACT,
  SERVICE_CONTRACT: OfferType.SERVICE_CONTRACT,
  PARTNER_EMPLOYEE_CONTRACT: OfferType.PARTNER_EMPLOYEE_CONTRACT,
  SERVICE_EMPLOYEE_CONTRACT: OfferType.SERVICE_EMPLOYEE_CONTRACT,
};

export const LocationRoleTypes = [
  RoleType.ADMIN_CONTRACT,
  RoleType.EMPLOYMENT_CONTRACT,
  RoleType.RENT_WORKPLACE,
  RoleType.PUBLIC_OFFER,
  RoleType.PARTNER_EMPLOYEE_CONTRACT,
  RoleType.SERVICE_EMPLOYEE_CONTRACT,
];

export const EmployeeOfferMap = {
  [OfferType.RENT_OFFICE]: RoleType.EMPLOYMENT_CONTRACT,
  [OfferType.PARTNER_CONTRACT]: RoleType.PARTNER_EMPLOYEE_CONTRACT,
  [OfferType.SERVICE_CONTRACT]: RoleType.SERVICE_EMPLOYEE_CONTRACT,
};
