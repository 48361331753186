<template>
  <v-system-bar v-if="showBar" app class="px-0" height="auto" style="z-index: 6">
    <v-toolbar elevation="0" tile>
      <v-btn icon height="40" width="40" style="opacity: 0.5" @click="os = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <img class="mr-2" src="/favicon-32x32.png" width="24" height="24" alt="" />
      <div class="text-body-2">{{ $t('Открыть в приложении') }}</div>
      <v-spacer />
      <BtnPrimary class="ml-1" rounded :disabled="disabled" @click="openApp">
        {{ $t('Открыть') }}
      </BtnPrimary>
    </v-toolbar>
  </v-system-bar>
</template>

<script>
import { getMobileOS } from '@/utils/utils';
import BtnPrimary from '@/components/ui/BtnPrimary';

export default {
  name: 'OpenInAppBar',

  components: { BtnPrimary },

  data: () => ({
    os: false,
  }),

  computed: {
    showBar() {
      return ['Android', 'iOS'].includes(this.os);
    },

    disabled() {
      return (
        (this.os === 'iOS' && !process.env.VUE_APP_APP_STORE_URL) ||
        (this.os === 'Android' && !process.env.VUE_APP_ANDROID_INTENT) ||
        this.os === 'Other'
      );
    },
  },

  mounted() {
    this.os = getMobileOS();
  },

  methods: {
    openApp() {
      if (this.os === 'iOS') {
        window.location.replace(process.env.VUE_APP_APP_STORE_URL);
      } else {
        window.location.replace(process.env.VUE_APP_ANDROID_INTENT);
      }
    },
  },
};
</script>
