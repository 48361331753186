import http from './http';

const baseUrl = (process.env.VUE_APP_API_OTRS || '').replace(/\/$/g, '');
const servicingUrl = (process.env.VUE_APP_API_SERVICES || '').replace(/\/$/g, '');

const coffeeBreakApi = {
  createOrder: data => http.post(`${baseUrl}/coffee-break/order`, data),
  productIncome: ({ id, ...data }) =>
    http.post(`${baseUrl}/coffee-break/admin/product/${id}/income`, data),

  category: {
    list: params => http.get(`${baseUrl}/coffee-break/category`, { params }),
  },

  product: {
    list: ({ serviceId, ...params }) => {
      const url = serviceId
        ? `${servicingUrl}/service/${serviceId}/product`
        : `${baseUrl}/coffee-break/product`;
      return http.get(url, { params });
    },
  },

  admin: {
    category: {
      list: params => http.get(`${baseUrl}/coffee-break/category`, { params }),
      create: data => http.post(`${baseUrl}/coffee-break/admin/category`, data),
      update: data => http.put(`${baseUrl}/coffee-break/admin/category/${data.id}`, data),
      delete: ({ id }) => http.delete(`${baseUrl}/coffee-break/admin/category/${id}`),
    },
    product: {
      list: params => http.get(`${baseUrl}/coffee-break/admin/product`, { params }),
      create: data => http.post(`${baseUrl}/coffee-break/admin/product`, data),
      update: data => http.put(`${baseUrl}/coffee-break/admin/product/${data.id}`, data),
      delete: ({ id }) => http.delete(`${baseUrl}/coffee-break/admin/product/${id}`),
    },
    stockProduct: {
      list: params => http.get(`${baseUrl}/coffee-break/admin/stock-product`, { params }),
    },
    order: {
      list: ({ admin, ...params }) => {
        return admin
          ? http.get(`${baseUrl}/coffee-break/admin/order`, { params })
          : http.get(`${baseUrl}/servicing/delivery`, { params });
      },
      find: ({ admin, id }) => {
        return admin
          ? http.get(`${baseUrl}/coffee-break/admin/order/${id}`)
          : http.get(`${baseUrl}/servicing/delivery/${id}`);
      },
      create: data => http.post(`${baseUrl}/coffee-break/admin/order`, data),
      update: ({ id, ...data }) => http.put(`${baseUrl}/coffee-break/admin/order/${id}`, data),
      fetchOrderStats: params => http.get(`${baseUrl}/coffee-break/admin/order/stats`, { params }),
      refreshAccess: ({ id }) =>
        http.patch(`${baseUrl}/servicing/admin/service-ticket/${id}/refresh-access`),
    },

    transaction: {
      list: params => http.get(`${baseUrl}/coffee-break/admin/transaction`, { params }),
    },

    menu: {
      list: params => http.get(`${baseUrl}/menu`, { params }),
      create: data => http.post(`${baseUrl}/menu`, data),
      update: ({ id, ...data }) => http.patch(`${baseUrl}/menu/${id}`, data),
      delete: ({ id }) => http.delete(`${baseUrl}/menu/${id}`),
    },
  },

  delivery: {
    deliveryStart: ({ id }) =>
      http.patch(`${baseUrl}/servicing/service-ticket/${id}/delivery-start`),
    deliveryEnd: ({ id }) => http.patch(`${baseUrl}/servicing/service-ticket/${id}/delivery-end`),
    deliveryCancel: ({ id, ...data }) =>
      http.patch(`${baseUrl}/servicing/service-ticket/${id}/delivery-cancel`, data),
  },
};

export default coffeeBreakApi;
