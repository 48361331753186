const location = [
  {
    path: '/admin/location',
    name: 'admin.location',
    redirect: { name: 'admin.location.info' },
    components: {
      default: () => import(/* webpackChunkName: "admin-mc" */ '@/views/admin/location/RoutePage'),
      tabs: () => import(/* webpackChunkName: "admin-mc" */ '@/views/admin/location/RouteTabs'),
    },
    children: [
      {
        path: '/admin/location',
        name: 'admin.location.info',
        component: () =>
          import(/* webpackChunkName: "admin-mc" */ '@/views/admin/location/LocationInfoPage'),
        meta: { isAuth: true },
      },
    ],
  },
  {
    path: '/admin/location/edit',
    name: 'admin.location.edit',
    component: () =>
      import(/* webpackChunkName: "admin-mc" */ '@/views/admin/location/LocationFormPage'),
    meta: { isAuth: true, backBtn: true },
  },
];

const requests = [
  {
    path: '/admin/requests',
    name: 'admin.requests',
    components: {
      default: () =>
        import(/* webpackChunkName: "admin" */ '@/views/admin/requests/RequestListPage'),
      tabs: () => import(/* webpackChunkName: "admin" */ '@/views/admin/requests/RouteTabs'),
      search: () => import(/* webpackChunkName: "admin" */ '@/views/admin/requests/RequestSearch'),
    },
    meta: { isAuth: true },
  },
  {
    path: '/admin/requests/:id(\\d+)/view',
    name: 'admin.requests.view',
    component: () =>
      import(/* webpackChunkName: "admin" */ '@/views/admin/requests/RequestViewPage'),
    props: route => ({ id: parseInt(route.params.id, 10) }),
    meta: { isAuth: true, backBtn: true },
  },
];

export default [
  ...require('@/router/routes/admin/admin.coffee-break').default,
  // ...require('@/router/routes/admin/admin.location').default,
  ...location,
  ...require('@/router/routes/admin/admin.rent').default,
  ...requests,
  ...require('@/router/routes/admin/admin.services').default,
  ...require('@/router/routes/admin/admin.support').default,
];
