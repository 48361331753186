import { makeDataModel } from '@/store/factory/dataModel';
import profileApi from '@/api/profileApi';

export default makeDataModel(
  { service: profileApi.notification },
  {
    actions: {
      async seen({ commit, dispatch }, { id }) {
        const response = await profileApi.notification.seen({ id });
        response && response.data && commit('UPDATE', response.data);
        // TODO: move outside, may be events
        dispatch('notificationStats/fetchStats', null, { root: true });
      },
      async unseen({ commit, dispatch }, { id }) {
        const response = await profileApi.notification.unseen({ id });
        response && response.data && commit('UPDATE', response.data);
        dispatch('notificationStats/fetchStats', null, { root: true });
      },
      async seenAll({ commit, dispatch }) {
        const response = await profileApi.notification.seenAll();
        if (response && response.data) {
          for (const item of response.data) {
            commit('UPDATE', item);
          }
        }
        dispatch('notificationStats/fetchStats', null, { root: true });
      },
      async deleteAll({ commit, dispatch }) {
        await profileApi.notification.deleteAll();
        commit('RESET');
        dispatch('notificationStats/fetchStats', null, { root: true });
      },
    },
  }
);
