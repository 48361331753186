export const OfferType = {
  PUBLIC_OFFER: 1,
  EMPLOYMENT_CONTRACT: 2,
  ROLE: 3,
  RESIDENT_CONTRACT: 4,
  SAAS_CONTRACT: 5,
  RENT_OFFICE: 6,
  RENT_WORKPLACE: 7,
  ADMIN_CONTRACT: 8,
  HOTEL: 9,
  PARTNER_CONTRACT: 10,
  SERVICE_CONTRACT: 11,
  PARTNER_EMPLOYEE_CONTRACT: 12,
  SERVICE_EMPLOYEE_CONTRACT: 13,
};

export const ContractOfferTypes = [
  OfferType.RENT_OFFICE,
  OfferType.RENT_WORKPLACE,
  OfferType.HOTEL,
  OfferType.PARTNER_CONTRACT,
  OfferType.SERVICE_CONTRACT,
];

export const RentOfferTypes = [
  OfferType.RENT_OFFICE,
  OfferType.RENT_WORKPLACE,
  OfferType.HOTEL,
  OfferType.PARTNER_CONTRACT,
  OfferType.SERVICE_CONTRACT,
];

export const OfferTypeLabel = {
  [OfferType.PUBLIC_OFFER]: 'Public Offer',
  [OfferType.EMPLOYMENT_CONTRACT]: 'Employee Contract',
  [OfferType.ROLE]: 'Role',
  [OfferType.RESIDENT_CONTRACT]: 'Resident contract',
  [OfferType.SAAS_CONTRACT]: 'Saas contract',
};

export const getOfferTypeItems = () =>
  Object.values(OfferType).map(key => ({
    value: key,
    text: OfferTypeLabel[key],
  }));

export const OfferorType = {
  COMPANY: 'co',
  USER: 'pe',
};

export const OfferStatus = {
  NEW: 'new',
  ACTIVE: 'active',
  EXPIRED: 'expired',
};

export const OfferStatusLabel = {
  [OfferStatus.NEW]: 'Новый',
  [OfferStatus.ACTIVE]: 'Активен',
  [OfferStatus.EXPIRED]: 'Закрыт',
};
