<template>
  <v-badge :value="!!content" :content="content" color="primary" class="mt-0" inline bordered>
    <DefaultItem v-bind="$attrs" />
  </v-badge>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import DefaultItem from '@/components/layout/menu/item/DefaultItem';

export default {
  name: 'CoffeeBreak',

  components: { DefaultItem },

  computed: {
    ...mapGetters('permissions', ['canAccess']),
    ...mapGetters('adminOrders', ['activeCount']),
    ...mapGetters('adminStock', ['totalLackCount']),

    content() {
      const count = /*this.totalLackCount +*/ this.activeCount;
      if (!count) return null;
      return count < 10 ? count : '9+';
    },
  },

  mounted() {
    if (this.canAccess('otrs:coffee-break.admin.order.stats')) {
      this.fetchOrderStats();
    }
    if (this.canAccess('otrs:coffee-break.admin.product.list')) {
      //this.fetchProducts();
    }
  },

  methods: {
    ...mapActions('adminOrders', ['fetchOrderStats']),
    ...mapActions('adminStock', ['fetchProducts']),
  },
};
</script>
