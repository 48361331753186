import { loadWith } from '@/store/factory/utils';

const config = {
  // name: 'main',
  dataModel: 'db/employees',
  params: {
    sort: 'id',
  },
};

export default {
  namespaced: true,

  getters: {
    getProfile: (state, _, __, rootGetters) => userId => rootGetters['db/profiles/getItem'](userId),

    getCompanyEmployees: (state, _, __, rootGetters) => companyId =>
      rootGetters[config.dataModel + '/getItems'](`c-${companyId}`).map(x => ({
        ...x,
        company: rootGetters['db/companies/getItem'](x.companyId),
        profile: rootGetters['db/profiles/getItem'](x.userId),
      })),

    getUserEmployments: (state, _, __, rootGetters) => userId => {
      if (userId && typeof userId !== 'number') {
        console.warn('[getUserEmployments] userId must be number');
      }

      return rootGetters[config.dataModel + '/allItems']
        .filter(x => x.userId === userId)
        .map(x => ({
          ...x,
          company: rootGetters['db/companies/getItem'](x.companyId),
          profile: rootGetters['db/profiles/getItem'](x.userId),
        }));
    },
  },

  actions: {
    async fetchCompanyEmployees({ dispatch, rootGetters }, { companyId }) {
      if (!rootGetters['permissions/canAccess']('profile:employee.list')) {
        return;
      }

      const key = `c-${companyId}`;

      const payload = { key, query: { company: companyId, expand: 'profile', ...config.params } };
      await dispatch(`${config.dataModel}/fetchItems`, payload, { root: true });

      const items = rootGetters[config.dataModel + '/getItems'](key);
      await loadWith(
        items,
        [{ field: 'userId', dataModel: 'db/profiles', idProp: 'userId' }],
        dispatch
      );
    },

    async fetchUserEmployments({ dispatch, rootGetters }, { userId }) {
      if (!rootGetters['permissions/canAccess']('profile:employee.list')) {
        return;
      }

      const key = `u-${userId}`;

      const payload = { key, query: { profile: userId, expand: 'company', ...config.params } };
      await dispatch(`${config.dataModel}/fetchItems`, payload, { root: true });

      const items = rootGetters[config.dataModel + '/getItems'](key);
      await loadWith(items, [{ field: 'companyId', dataModel: 'db/companies' }], dispatch);
    },

    async fetchEmployments({ dispatch, rootGetters }, { userIds }) {
      if (!rootGetters['permissions/canAccess']('profile:employee.list')) {
        return;
      }

      const key = `emp`;

      const payload = {
        key,
        query: { profile: userIds.join(','), expand: 'company', ...config.params },
      };
      await dispatch(`${config.dataModel}/fetchItems`, payload, { root: true });

      const items = rootGetters[config.dataModel + '/getItems'](key);
      await loadWith(items, [{ field: 'companyId', dataModel: 'db/companies' }], dispatch);
    },
  },
};
