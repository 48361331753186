import { ServiceType } from '@/constants/service';
import { stStatus } from '@/constants/stStatus';
import { PaymentMethod, PaymentStatus, PaymentType } from '@/constants/payment';

export default {
  computed: {
    $ServiceType() {
      return ServiceType;
    },
    $stStatus() {
      return stStatus;
    },
    $PaymentStatus() {
      return PaymentStatus;
    },
    $PaymentType() {
      return PaymentType;
    },
    $PaymentMethod() {
      return PaymentMethod;
    },
  },
};
