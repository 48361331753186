export const stStatus = {
  CREATING: 'creating',
  NEW: 'new',
  INVOICING: 'invoicing',
  WAITING_FOR_WIRE_PAYMENT: 'waiting for wire payment',
  WAITING_FOR_PAYMENT: 'waiting for payment',
  CONFIRMED: 'confirmed',
  ACTIVE: 'active',
  IN_WORK: 'in_work',
  PACKED: 'packed',
  DELIVERY: 'delivery',
  PROCESSING_REQUIRED: 'processing required',
  PENDING_CLOSE: 'pending_close',
  CLOSED: 'closed',
  CANCELED: 'canceled',
};

export const statusFilterList = [
  stStatus.NEW,
  stStatus.CONFIRMED,
  stStatus.ACTIVE,
  stStatus.IN_WORK,
  stStatus.PACKED,
  stStatus.DELIVERY,
  stStatus.PROCESSING_REQUIRED,
  stStatus.PENDING_CLOSE,
  stStatus.CLOSED,
  stStatus.CANCELED,
];

export const coffeeStatusList = [
  stStatus.NEW,
  stStatus.CONFIRMED,
  stStatus.ACTIVE,
  stStatus.PACKED,
  stStatus.DELIVERY,
  stStatus.CLOSED,
  stStatus.PROCESSING_REQUIRED,
  stStatus.CANCELED,
];

export const courierStatusList = [
  stStatus.ACTIVE,
  stStatus.PACKED,
  stStatus.DELIVERY,
  stStatus.CLOSED,
  stStatus.PROCESSING_REQUIRED,
];

export const closedStatuses = [stStatus.CLOSED, stStatus.CANCELED];
