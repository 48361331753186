import otrsApi from '@/api/otrsApi';
import coffeeBreakApi from '@/api/coffeeBreakApi';
import { adminPosterService } from '@/api/otrsPosterApi';
import { stStatus } from '@/constants/stStatus';
import { makeSimpleList } from '@/store/factory/simpleList';

export default makeSimpleList(
  { name: 'main', dataModel: 'db/adminOrders' },
  {
    state: {
      stats: [],
    },

    getters: {
      activeCount: state =>
        state.stats.filter(x => x.status === stStatus.NEW).reduce((sum, x) => sum + x.count, 0),
    },

    actions: {
      async createOrder({ dispatch }, payload) {
        return dispatch('db/adminOrders/create', payload, { root: true });
      },

      async updateOrder({ dispatch }, payload) {
        return dispatch('db/adminOrders/update', payload, { root: true });
      },

      async updateState({ commit }, { id, state }) {
        return otrsApi.admin.serviceTicket.updateState({ id, state }).then(res => {
          commit('db/adminOrders/UPDATE', res.data, { root: true });
          return res;
        });
      },

      async refreshAccess({ commit }, { id }) {
        return coffeeBreakApi.admin.order.refreshAccess({ id }).then(res => {
          commit('db/adminOrders/UPDATE', res.data, { root: true });
          return res;
        });
      },

      async updateResponsible({ commit }, { id, responsibleId }) {
        return otrsApi.admin.serviceTicket.updateResponsible({ id, responsibleId }).then(res => {
          commit('db/adminOrders/UPDATE', res.data, { root: true });
          return res;
        });
      },

      async updateDeadline({ commit }, { id, ...data }) {
        return otrsApi.admin.serviceTicket.updateDeadline({ id, ...data }).then(res => {
          commit('db/adminOrders/UPDATE', res.data, { root: true });
          return res;
        });
      },

      async paymentRefunded({ commit }, { id }) {
        return otrsApi.admin.serviceTicket.paymentRefunded({ id }).then(res => {
          commit('db/adminOrders/UPDATE', res.data, { root: true });
          return res;
        });
      },

      async fetchOrderStats({ commit }) {
        return coffeeBreakApi.admin.order.fetchOrderStats().then(res => {
          commit('SET_STATS', res.data);
        });
      },

      async updatePosterOrder({ commit }, payload) {
        return adminPosterService.update(payload).then(res => {
          commit('db/adminOrders/UPDATE', res.data, { root: true });
        });
      },

      async deliveryStart({ commit }, { id }) {
        return coffeeBreakApi.delivery.deliveryStart({ id }).then(res => {
          commit('db/adminOrders/UPDATE', res.data, { root: true });
        });
      },

      async deliveryEnd({ commit }, { id }) {
        return coffeeBreakApi.delivery.deliveryEnd({ id }).then(res => {
          commit('db/adminOrders/UPDATE', res.data, { root: true });
        });
      },

      async deliveryCancel({ commit }, { id, comment }) {
        return coffeeBreakApi.delivery.deliveryCancel({ id, comment }).then(res => {
          commit('db/adminOrders/UPDATE', res.data, { root: true });
        });
      },
    },

    mutations: {
      SET_STATS(state, payload) {
        state.stats = payload;
      },
    },
  }
);
