import { loadWith } from '@/store/factory/utils';
import { RoleType } from '@/constants/rbac';
import { uniqueFilter } from '@/utils/arrays';

export function makeManageEmployeesModule({ dbModule, dbCompanies, dbProfiles }) {
  const config = {
    // name: 'main',
    dataModel: dbModule,
    params: {
      sort: 'id',
    },
  };

  return {
    namespaced: true,

    getters: {
      getProfile: (state, _, __, rootGetters) => userId =>
        rootGetters[`${dbProfiles}/getItem`](userId),

      getCompanyEmployees: (state, _, __, rootGetters) => companyId =>
        rootGetters[config.dataModel + '/getItems'](`c-${companyId}`).map(x => ({
          ...x,
          company: rootGetters[`${dbCompanies}/getItem`](x.companyId),
          profile: rootGetters[`${dbProfiles}/getItem`](x.userId),
        })),

      getUserEmployments: (state, _, __, rootGetters) => userId => {
        if (userId && typeof userId !== 'number') {
          console.warn('[getUserEmployments] userId must be number');
        }

        return rootGetters[config.dataModel + '/allItems']
          .filter(x => x.userId === userId)
          .map(x => ({
            ...x,
            company: rootGetters[`${dbCompanies}/getItem`](x.companyId),
            profile: rootGetters[`${dbProfiles}/getItem`](x.userId),
          }));
      },
    },

    actions: {
      async fetchCompanyEmployees({ dispatch, rootGetters }, { companyId }) {
        const key = `c-${companyId}`;

        const payload = { key, query: { company: companyId, expand: 'profile', ...config.params } };
        await dispatch(`${config.dataModel}/fetchItems`, payload, { root: true });

        const items = rootGetters[config.dataModel + '/getItems'](key);
        await loadWith(
          items,
          [{ field: 'userId', dataModel: dbProfiles, idProp: 'userId' }],
          dispatch
        );
      },

      async fetchUserEmployments({ dispatch, rootGetters }, { userId }) {
        const key = `u-${userId}`;

        const payload = { key, query: { profile: userId, expand: 'company', ...config.params } };
        await dispatch(`${config.dataModel}/fetchItems`, payload, { root: true });

        const items = rootGetters[config.dataModel + '/getItems'](key);
        await loadWith(items, [{ field: 'companyId', dataModel: dbCompanies }], dispatch);
      },

      async fetchEmployments({ dispatch, rootGetters }, { locationId, userIds, ...rest }) {
        const key = `emp`;
        const query = {
          'company.location_id': locationId,
          profile: userIds.filter(uniqueFilter).join(','),
          expand: 'company',
          ...config.params,
          ...rest,
        };

        await dispatch(`${config.dataModel}/fetchItems`, { key, query }, { root: true });

        const items = rootGetters[config.dataModel + '/getItems'](key);
        await loadWith(items, [{ field: 'companyId', dataModel: dbCompanies }], dispatch);
      },

      async saveProfile({ dispatch, getters }, { userId, profile: form }) {
        if (userId) {
          const sProfile = getters.getProfile(userId);
          if (
            sProfile.lastName !== form.lastName ||
            sProfile.firstName !== form.firstName ||
            sProfile.email !== form.email
          ) {
            await dispatch(
              `${dbProfiles}/update`,
              {
                id: userId,
                lastName: form.lastName,
                firstName: form.firstName,
                email: form.email,
              },
              { root: true }
            );
          }

          return userId;
        }

        const { data: user } = await dispatch(
          'systemAdmin/db/users/create',
          { phone: form.phone, email: form.email },
          { root: true }
        );

        await dispatch(`${dbProfiles}/create`, { ...form, userId: user.id }, { root: true });

        return user.id;
      },

      async saveEmployees({ dispatch, getters }, { companyId, data, offerType }) {
        let employees = getters.getCompanyEmployees(companyId);
        if (offerType) {
          employees = employees.filter(x => x.roles.find(x => x.offerType === offerType));
        }

        const userIds = data.map(x => x.userId);
        const deleteItems = employees.filter(x => !userIds.includes(x.userId));
        const deletePromises = deleteItems.map(x =>
          dispatch(`${config.dataModel}/delete`, { id: x.id }, { root: true })
        );

        const savedUserIds = employees.map(x => x.userId);
        const createItems = data.filter(x => !savedUserIds.includes(x.userId));
        const createPromises = createItems.map(x =>
          dispatch(
            `${config.dataModel}/create`,
            {
              companyId: companyId,
              userId: x.userId,
              title: x.title || null,
              offerType: x.offerType || RoleType.EMPLOYMENT_CONTRACT,
              roles: x.roles,
              units: x.units || [],
              accessLevels: x.accessLevels || [],
              publicVisible: typeof x.publicVisible === 'boolean' ? x.publicVisible : undefined,
            },
            { root: true }
          )
        );

        const intersectUserIds = userIds.filter(id => savedUserIds.includes(id));
        const updateItems = data.filter(x => intersectUserIds.includes(x.userId));
        const updatePromises = updateItems.map(x =>
          dispatch(
            `${config.dataModel}/update`,
            {
              id: employees.find(y => y.userId === x.userId).id,
              companyId: companyId,
              userId: x.userId,
              title: x.title || null,
              offerType: x.offerType || RoleType.EMPLOYMENT_CONTRACT,
              roles: x.roles,
              units: x.units || [],
              accessLevels: x.accessLevels || [],
              publicVisible: typeof x.publicVisible === 'boolean' ? x.publicVisible : undefined,
            },
            { root: true }
          )
        );

        await Promise.all(deletePromises);
        await Promise.all(createPromises);
        await Promise.all(updatePromises);
      },

      async saveEmployments({ dispatch, getters }, { userId, data }) {
        const saved = getters.getUserEmployments(userId);

        const companyIds = data.map(x => x.companyId);
        const deleteItems = saved.filter(x => !companyIds.includes(x.companyId));
        const deletePromises = deleteItems.map(x =>
          dispatch(`${config.dataModel}/delete`, { id: x.id }, { root: true })
        );

        const savedCompanyIds = saved.map(x => x.companyId);
        const createItems = data.filter(x => !savedCompanyIds.includes(x.companyId));
        const createPromises = createItems.map(x =>
          dispatch(
            `${config.dataModel}/create`,
            {
              companyId: x.companyId,
              userId: userId,
              title: x.title || null,
              offerType: x.offerType || RoleType.EMPLOYMENT_CONTRACT,
              roles: x.roles,
              units: x.units || [],
              accessLevels: x.accessLevels || [],
              publicVisible: typeof x.publicVisible === 'boolean' ? x.publicVisible : undefined,
            },
            { root: true }
          )
        );

        const intersectCompanyIds = companyIds.filter(id => savedCompanyIds.includes(id));
        const updateItems = data.filter(x => intersectCompanyIds.includes(x.companyId));
        const updatePromises = updateItems.map(x =>
          dispatch(
            `${config.dataModel}/update`,
            {
              id: saved.find(y => y.companyId === x.companyId).id,
              companyId: x.companyId,
              userId: userId,
              title: x.title || null,
              offerType: x.offerType || RoleType.EMPLOYMENT_CONTRACT,
              roles: x.roles,
              units: x.units || [],
              accessLevels: x.accessLevels || [],
              publicVisible: typeof x.publicVisible === 'boolean' ? x.publicVisible : undefined,
            },
            { root: true }
          )
        );

        await Promise.all(deletePromises);
        await Promise.all(createPromises);
        await Promise.all(updatePromises);
      },

      async addUserToLocation({ dispatch }, payload) {
        await dispatch(`${dbProfiles}/addUserToLocation`, payload, { root: true });
      },
    },
  };
}
