import { makeSimpleList } from '@/store/factory/simpleList';
import { OfferorType, ContractOfferTypes } from '@/constants/offer';
import { uniqueFilter } from '@/utils/arrays';

export default makeSimpleList(
  {
    name: 'main',
    dataModel: 'db/offerAcceptances',
    params: {
      offerType: ContractOfferTypes.join(','),
      sort: 'accepted_at-',
    },
  },
  {
    actions: {
      async submitForm({ dispatch, rootGetters }, { id, form }) {
        const location = rootGetters['auth/location'];
        const offeror = `mc-${location.companyId}`;

        let acceptor;
        if (form.tenantType === OfferorType.COMPANY) {
          if (!form.companyId) {
            const { data: newCompany } = await dispatch(
              'db/adminCompanies/create',
              {
                shortName: form.companyShortName,
                fullName: form.companyFullName,
                roles: form.companyRoles,
                logo: process.env.VUE_APP_DEFAULT_COMPANY_LOGO,
              },
              { root: true }
            );
            form.companyId = newCompany.id;
          }
          acceptor = `co-${form.companyId}`;
        }
        if (form.tenantType === OfferorType.USER) {
          const userId = await dispatch(
            'adminEmployees/saveProfile',
            { userId: form.userId, profile: form.profile },
            { root: true }
          );
          const profile = rootGetters['adminEmployees/getProfile'](userId);

          form.userId = userId;
          form.profile = { ...profile };

          acceptor = `pe-${form.userId}`;
        }

        await dispatch('roles/fetchItems', null, { root: true });
        const roles = rootGetters['roles/items'];
        const offerRoles = roles
          .filter(x => !x.isLocal)
          .filter(x => (x.offerTypes || []).includes(form.offerType))
          .map(x => x.id);

        if (form.tenantType === OfferorType.COMPANY) {
          await dispatch(
            'adminEmployees/saveEmployees',
            { companyId: form.companyId, data: form.employees },
            { root: true }
          );
        }

        const offerData = {
          contractNumber: form.contractNumber,
          contractDate: form.contractDate,
          offeror: offeror,
          offerType: form.offerType,
          startedAt: form.startedAt,
          expireAt: form.expireAt,
          units: form.units,
          accessLevels: form.accessLevels,
          roles: [...offerRoles].filter(uniqueFilter),
        };

        if (!id) {
          const { data: offer } = await dispatch('db/offers/create', offerData, { root: true });
          const { data: offerAcceptance } = await dispatch(
            'db/offerAcceptances/create',
            { offerId: offer.id, acceptor },
            { root: true }
          );

          return offerAcceptance.id;
        }

        const offerId = rootGetters['db/offerAcceptances/getItem'](id).offer.id;
        await dispatch('db/offers/update', { id: offerId, ...offerData }, { root: true });
        await dispatch('db/offerAcceptances/update', { id, acceptor, offerId }, { root: true });

        return id;
      },

      async deleteContract({ dispatch }, { offerId }) {
        await dispatch('db/offers/delete', { id: offerId }, { root: true });
      },
    },
  }
);
