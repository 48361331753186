import acsApi from '@/api/acsApi';
import acsProviders from '@/store/modules/systemAdmin/acsProviders';
import authApi from '@/api/authApi';
import { makeDataModel } from '@/store/factory/dataModel';
import { makeSearchModel } from '@/store/factory/search';
import { makeSimpleList } from '@/store/factory/simpleList';
import locationStructure from '@/store/modules/systemAdmin/locationStructure';
import manageLocations from '@/store/modules/systemAdmin/manageLocations';
import profileApi from '@/api/profileApi';
import saasEmployees from '@/store/modules/systemAdmin/saasEmployees';

export default {
  namespaced: true,
  modules: {
    db: {
      namespaced: true,
      modules: {
        locations: makeDataModel({ service: authApi.admin.location }),
        companies: makeDataModel({ service: profileApi.saas.company }),
        users: makeDataModel({ service: authApi.admin.user, idProp: 'userId' }),
        profiles: makeDataModel(
          { service: profileApi.saas.profile, idProp: 'userId' },
          {
            actions: {
              async addUserToLocation({ commit, dispatch }, { userId, locationId, profile }) {
                const response = await profileApi.saas.profile.addToLocation({
                  ...profile,
                  id: userId,
                  locationId: locationId,
                });
                if (response.data.fullName) {
                  const query = { id: userId };
                  commit('ADD_QUERY', { key: JSON.stringify(query), response, query });
                } else {
                  await dispatch('update', { ...profile, id: userId });
                }
              },
            },
          }
        ),
        employees: makeDataModel({ service: profileApi.admin.employee, idProp: 'userId' }),
        accessLevels: makeDataModel({ service: acsApi.operator.accessLevel }),
      },
    },

    // pages
    manageLocations: manageLocations,
    locationStructure: locationStructure,
    acsProviders: acsProviders,

    // ---
    locations: makeSimpleList({
      name: 'main',
      dataModel: 'systemAdmin/db/locations',
    }),
    companies: makeSimpleList({
      name: 'main',
      dataModel: 'systemAdmin/db/companies',
    }),
    saasEmployees: saasEmployees,

    // ---
    locationSearch: makeSearchModel({
      name: 'search',
      dataModel: 'systemAdmin/db/locations',
      limit: 100,
    }),
    profilesSearch: makeSearchModel({
      name: 'search',
      dataModel: 'systemAdmin/db/profiles',
    }),
  },
};
