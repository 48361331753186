import { makeDataModel } from '@/store/factory/dataModel';
import { AdminReservationService } from '@/api/bookingApi';
import otrsApi from '@/api/otrsApi';

export default makeDataModel(
  { service: AdminReservationService },
  {
    actions: {
      async paymentRefunded({ commit }, { id }) {
        return otrsApi.admin.serviceTicket.paymentRefunded({ id }).then(res => {
          commit('UPDATE', res.data);
          return res;
        });
      },

      async updateState({ commit }, { id, state }) {
        return otrsApi.admin.serviceTicket.updateState({ id, state }).then(res => {
          commit('UPDATE', res.data);
          return res;
        });
      },

      async updateResponsible({ commit }, { id, responsibleId }) {
        return otrsApi.admin.serviceTicket.updateResponsible({ id, responsibleId }).then(res => {
          commit('UPDATE', res.data);
          return res;
        });
      },

      async updateDeadline({ commit }, { id, ...data }) {
        return otrsApi.admin.serviceTicket.updateDeadline({ id, ...data }).then(res => {
          commit('UPDATE', res.data);
          return res;
        });
      },
    },
  }
);
