import brandConfig from '@/brand';
import { RG } from '@/constants/routeGroup';
import NotFound from '@/views/NotFound';
import adminRoutes from './admin';
import commonRoutes from './common';

export default [
  {
    path: '/',
    redirect: brandConfig.homePage,
    meta: { isAuth: true },
  },
  ...commonRoutes,
  ...adminRoutes.map(x => ({
    ...x,
    meta: { group: RG.admin, isAuth: true, ...x.meta },
  })),
  {
    path: '/404',
    name: '404',
    component: NotFound,
  },
  {
    path: '*',
    component: NotFound,
  },
];
