import http from './http';

const baseUrl = (process.env.VUE_APP_API_SERVICES || '').replace(/\/$/g, '');

const serviceApi = {
  fetchCategories(params) {
    return http.get(`${baseUrl}/category`, { params });
  },
  fetchServices(params) {
    return http.get(`${baseUrl}/service`, { params });
  },

  fetchFavorites(params) {
    return http.get(`${baseUrl}/service/favorite`, { params });
  },
  addFavorite(id) {
    return http.post(`${baseUrl}/service/${id}/favorite`);
  },
  deleteFavorite(id) {
    return http.delete(`${baseUrl}/service/${id}/favorite`);
  },

  fetchAdminCategories(params) {
    return http.get(`${baseUrl}/admin/category`, { params });
  },
  fetchAdminServices(params) {
    return http.get(`${baseUrl}/admin/service`, { params });
  },

  createCategory(data) {
    return http.post(`${baseUrl}/category`, data);
  },
  updateCategory(id, data) {
    return http.put(`${baseUrl}/category/${id}`, data);
  },
  deleteCategory(id) {
    return http.delete(`${baseUrl}/category/${id}`);
  },

  updateCategoryPriority(data) {
    return http.put(`${baseUrl}/category/priority`, data);
  },

  createService(data) {
    return http.post(`${baseUrl}/service`, data);
  },
  updateService(id, data) {
    return http.put(`${baseUrl}/service/${id}`, data);
  },
  deleteService(id) {
    return http.delete(`${baseUrl}/service/${id}`);
  },

  updateServicePriority(data) {
    return http.put(`${baseUrl}/service/priority`, data);
  },

  createTicket(id, data) {
    return http.post(`${baseUrl}/service/${id}/ticket`, data);
  },
  createTechSupportTicket(data) {
    return http.post(`${baseUrl}/tech-support`, data);
  },

  fetchWorkDates({ serviceId, ...params }) {
    return http.get(`${baseUrl}/service/${serviceId}/workdate`, { params });
  },
  fetchWorkTime({ serviceId, date }) {
    return http.get(`${baseUrl}/service/${serviceId}/worktime/${date}`);
  },

  fetchServiceSlots({ serviceId, ...params }) {
    return http.get(`${baseUrl}/service/${serviceId}/slot`, { params });
  },

  fetchLocationSupportService({ locationId }) {
    return http.get(`${baseUrl}/location/${locationId}/tech-support`);
  },
  updateLocationSupportService({ locationId, ...data }) {
    return http.put(`${baseUrl}/location/${locationId}/tech-support`, data);
  },
};

export default serviceApi;

export const serviceTicketService = {
  list: params => http.get(`${baseUrl}/service-ticket`, { params }),
  find: ({ id }) => http.get(`${baseUrl}/service-ticket/${id}`),
  updateState: ({ id, ...data }) => http.patch(`${baseUrl}/service-ticket/${id}/state`, data),
  finalize: ({ id, ...data }) => http.patch(`${baseUrl}/service-ticket/${id}/finalize`, data),
  externalPayment: ({ id, ...data }) =>
    http.post(`${baseUrl}/service-ticket/${id}/external-payment`, data),
};
