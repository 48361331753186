import { ServiceType } from '@/constants/service';

const tColors = {
  't-dark': { base: '#353439' },
  't-grey': { base: '#6F7882' },
  't-back-light': { base: '#F7F9FC' },
  't-menu-bg': { base: '#E5EBF4' },
  't-additional': { base: '#7694B8' },
  't-positive': { base: '#00B040' },
  't-attention': { base: '#FF6B00' },
  't-disabled': { base: '#E1E3E6' },
};

export default {
  isOlveryPlatform: false,
  homePage: { name: 'admin.requests' },
  serviceTypes: [ServiceType.BOOKING, ServiceType.COMMERCIAL, ServiceType.COFFEE_BREAK],
  hideChat: false,
  img: {
    publicBg: require('./assets/public.jpg'),
    authLogo: require('./assets/logo_vert.jpg'),
    navLogo: require('./assets/logo_hor.jpg'),
    accessPoster: require('./assets/access_poster.png'),
  },
  themes: {
    light: {
      primary: '#0736F4',
      secondary: '#424242',
      accent: '#82B1FF',
      error: '#FF5252',
      info: '#2196F3',
      success: '#4CAF50',
      warning: '#FB8C00',
      ...tColors,
    },
    dark: {
      primary: '#0736F4',
      secondary: '#424242',
      accent: '#FF4081',
      error: '#FF5252',
      info: '#2196F3',
      success: '#4CAF50',
      warning: '#FB8C00',
      ...tColors,
    },
  },
};
