import { makeDataModel } from '@/store/factory/dataModel';
import { serviceTicketService } from '@/api/servicesApi';

export default makeDataModel(
  { service: serviceTicketService },
  {
    actions: {
      updateState({ commit }, { id, state }) {
        return serviceTicketService
          .updateState({ id, state })
          .then(res => commit('UPDATE', res.data));
      },
      externalPayment({ commit }, { id, ...data }) {
        return serviceTicketService
          .externalPayment({ id, ...data })
          .then(res => commit('UPDATE', res.data));
      },
    },
  }
);
