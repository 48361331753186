import i18n from '@/plugins/i18n';
import CoffeeBreak from '@/components/layout/menu/item/CoffeeBreak.vue';

export default [
  {
    title: i18n.t('nav.admin.rent'),
    route: { name: 'admin.rent' },
    icon: 'mdi-calendar-clock',
    guard: ['otrs:conference.reservation.list', 'otrs:conference.reservation.calendar'],
  },
  {
    title: i18n.t('nav.admin.store'),
    route: { name: 'admin.coffee-break' },
    icon: 'mdi-coffee-outline',
    guard: ['otrs:coffee-break.admin.order.list', 'otrs:servicing.service-ticket-delivery.list'],
    component: CoffeeBreak,
  },
  {
    title: i18n.t('nav.admin.requests'),
    route: { name: 'admin.requests' },
    icon: 'mdi-forum-outline',
    guard: 'otrs:admin.request.list',
  },
  {
    title: i18n.t('nav.admin.services'),
    route: { name: 'admin.services' },
    icon: 'mdi-shape-outline',
    guard: 'otrs:service.create',
  },
];
