import Vue from 'vue';
import locationApi from '@/api/locationApi';
import { treeToList } from '@/utils/tree';
import nfcApi from '@/api/nfcApi';

const initialState = () => ({
  structTree: {},
  devicesByUnit: {
    // 1 => [{id: 3, providerId: 5, name: "Офис 205", mode: "UNLOCKED", unitId: 39}, ...]
  },
});

export default {
  namespaced: true,

  state: initialState,

  getters: {
    getStructTree: state => locationId => state.structTree[locationId] ?? null,

    getUnitAccessPoints: (state, getters, __, rootGetters) => unitId => {
      return rootGetters['db/accessPoints/allItems']
        .filter(x => x.unitId === unitId)
        .map(x => getters.getAccessPoint(x.id));
    },

    getAccessPoint: (state, getters, _, rootGetters) => id => {
      const accessPoint = rootGetters['db/accessPoints/getItem'](id);

      return (
        accessPoint && {
          ...accessPoint,
          tokens: rootGetters['db/nfcTokens/allItems'].filter(
            x => x.accessPointId === accessPoint.id
          ),
        }
      );
    },

    getViewTokens: (state, _, __, rootGetters) => unitId =>
      rootGetters['db/nfcTokens/allItems'].filter(
        x => x.unitId === unitId && x.accessPointId === null
      ),

    getViewToken: (state, _, __, rootGetters) => id => rootGetters['db/nfcTokens/getItem'](id),

    tokenExists: (state, _, __, rootGetters) => (token, excludedId) => {
      return !!rootGetters['db/nfcTokens/allItems'].find(
        x => x.token === token && x.id !== excludedId
      );
    },

    getDevicesByUnit: state => unitId => state.devicesByUnit[unitId],
  },

  actions: {
    async fetchStructTree({ commit }, { locationId }) {
      return locationApi.location.fetchTree({ locationId }).then(res => {
        commit('SET_TREE', { locationId, data: res.data });
        commit(
          'db/units/ADD_QUERY',
          {
            key: `ref-${JSON.stringify({ locationId })}`,
            response: { ...res, data: treeToList(res.data) },
            query: { locationId },
          },
          { root: true }
        );
      });
    },

    async createUnit({ dispatch }, payload) {
      const { locationId, parentId, ...rest } = payload;
      const { data: unit } = await locationApi.unit.create({ parentId, ...rest });
      await dispatch('fetchStructTree', { locationId });

      return unit;
    },

    async updateUnit({ dispatch }, payload) {
      const { locationId } = payload;
      const { data: unit } = await locationApi.unit.update(payload);
      await dispatch('fetchStructTree', { locationId });

      return unit;
    },

    async deleteUnit({ dispatch, rootGetters }, { locationId, id }) {
      const proccessAccessPoints = rootGetters['db/accessPoints/allItems']
        .filter(x => x.unitId === id)
        .map(x => dispatch('db/accessPoints/delete', { id: x.id }, { root: true }));

      await Promise.all(proccessAccessPoints);
      await locationApi.unit.delete({ id });
      await dispatch('fetchStructTree', { locationId });
    },

    async fetchAccessPointsData({ dispatch }, { locationId }) {
      return Promise.all([
        dispatch(
          'db/accessPoints/fetchItems',
          { key: locationId, query: { locationId, limit: 1000 } },
          { root: true }
        ),
        dispatch(
          'db/nfcTokens/fetchItems',
          { key: locationId, query: { locationId, limit: 1000 } },
          { root: true }
        ),
      ]);
    },

    async saveAccessPointData(
      { getters, dispatch, rootGetters },
      { id, unitId, locationId, form }
    ) {
      let accessPointId = id;
      if (!accessPointId) {
        const { data } = await dispatch(
          'db/accessPoints/create',
          {
            deviceId: form.deviceId,
            direction: form.direction,
            acsId: form.acsId,
            unitId,
            locationId,
          },
          { root: true }
        );
        accessPointId = data.id;
      } else {
        await dispatch(
          'db/accessPoints/update',
          {
            id: id,
            deviceId: form.deviceId,
            direction: form.direction,
            acsId: form.acsId,
            unitId,
            locationId,
          },
          { root: true }
        );
      }

      let accessPoint = getters.getAccessPoint(accessPointId);

      await Promise.all(
        rootGetters['db/nfcTokens/allItems']
          .filter(x => x.accessPointId === accessPointId)
          .filter(x => x.token !== form.nfcToken && x.token !== form.qrCodeToken)
          .map(x => dispatch('db/nfcTokens/delete', { id: x.id }, { root: true }))
      );

      accessPoint = getters.getAccessPoint(accessPointId);

      const nfcToken = (accessPoint?.tokens ?? []).find(x => x.kind === 'nfc');
      const qrCodeToken = (accessPoint?.tokens ?? []).find(x => x.kind === 'qr-code');

      if (form.nfcOpen && !nfcToken) {
        await dispatch(
          'db/nfcTokens/create',
          { accessPointId, unitId, locationId, kind: 'nfc', token: form.nfcToken },
          { root: true }
        );
      }

      if (form.qrCodeOpen && !qrCodeToken) {
        await dispatch(
          'db/nfcTokens/create',
          { accessPointId, unitId, locationId, kind: 'qr-code', token: form.qrCodeToken },
          { root: true }
        );
      }

      await dispatch('fetchAccessPointsData', { locationId });
    },

    async deleteAccessPointData({ getters, dispatch }, { id }) {
      let apData = getters.getAccessPoint(id);
      await Promise.all(
        apData.tokens.map(x => dispatch('db/nfcTokens/delete', { id: x.id }, { root: true }))
      );
      await dispatch('db/accessPoints/delete', { id: id }, { root: true });
    },

    async saveQrCode({ dispatch }, payload) {
      if (payload.id) {
        await dispatch('db/nfcTokens/update', payload, { root: true });
      } else {
        await dispatch('db/nfcTokens/create', payload, { root: true });
      }
    },

    async deleteQrCode({ dispatch }, { id }) {
      await dispatch('db/nfcTokens/delete', { id }, { root: true });
    },

    async fetchDevicesByUnit({ commit }, { locationId, unitId }) {
      const { data } = await nfcApi.fetchDevicesByUnit({ locationId, unitId });
      commit('SET_UNIT_DEVICES', { unitId, data });
    },

    async setDeviceMode({ dispatch, state }, { locationId, unitId, deviceId, mode }) {
      const device = state.devicesByUnit[unitId].find(x => x.id === deviceId);
      await nfcApi.admin.setDeviceMode({ deviceId, providerId: device.providerId, mode });
      await dispatch('fetchDevicesByUnit', { locationId, unitId });
    },
  },

  mutations: {
    RESET(state) {
      const newState = initialState();
      Object.keys(newState).forEach(key => (state[key] = newState[key]));
    },

    SET_DATA(state, obj) {
      Object.keys(obj).forEach(key => (state[key] = obj[key]));
    },

    SET_TREE(state, { locationId, data }) {
      Vue.set(state.structTree, locationId, data);
    },

    SET_UNIT_DEVICES(state, { unitId, data }) {
      Vue.set(state.devicesByUnit, unitId, data);
    },
  },
};
