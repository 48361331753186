import servicesApi from '@/api/servicesApi';
import { uploadFiles } from '@/api/storageApi';
import { DynamicFieldType } from '@/constants/dynamicFields';
import i18n from '@/plugins/i18n';

const initialState = () => ({
  loaded: false,
  loading: false,
  categories: [],
  services: [],
  favoriteIds: [],
});

const state = initialState();

const getters = {
  loaded: state => state.loaded,
  loading: state => state.loading,

  categories: state => state.categories,
  services: state => state.services,
  favoriteIds: state => state.favoriteIds,

  getCategoryById: state => id => state.categories.find(x => x.id === id),
  getServiceById: state => id => state.services.find(x => x.id === id),

  getChildrenCategories: state => id => state.categories.filter(x => x.parentId === id),
  getCategoryServices: state => id => id ? state.services.filter(x => x.categoryId === id) : [],

  newServices: state => state.services.filter(x => x.new),
  favoriteServices: state => state.services.filter(x => state.favoriteIds.includes(x.id)),

  homeCategories: state => {
    const items = state.categories.filter(x => x.homePosition !== null);
    items.sort((a, b) => a.homePosition - b.homePosition);
    return items;
  },

  partnerCategory: (state, getters) => getters.categories.find(x => x.partnerOffers),

  homeServices: state => {
    const items = state.services.filter(x => x.homePosition !== null);
    items.sort((a, b) => a.homePosition - b.homePosition);
    return items;
  },
};

const actions = {
  async fetchCatalog({ commit, dispatch }) {
    commit('SET_LOADING', true);
    return Promise.all([
      dispatch('fetchCategories'),
      dispatch('fetchServices'),
      dispatch('fetchFavorites'),
    ])
      .then(() => {
        commit('LOADED');
      })
      .finally(() => {
        commit('SET_LOADING', false);
      });
  },

  async fetchCategories({ commit }) {
    return servicesApi.fetchCategories().then(res => commit('SET_CATEGORIES', res.data));
  },

  async fetchServices({ commit }) {
    return servicesApi.fetchServices().then(res => commit('SET_SERVICES', res.data));
  },

  async fetchFavorites({ commit }) {
    return servicesApi.fetchFavorites().then(res =>
      commit(
        'SET_FAVORITE_IDS',
        res.data.map(x => x.id)
      )
    );
  },

  async markFavorite({ commit, state }, id) {
    const exists = state.favoriteIds.includes(id);
    const backup = [...state.favoriteIds];

    if (exists) {
      commit(
        'SET_FAVORITE_IDS',
        state.favoriteIds.filter(x => x !== id)
      );
    } else {
      commit('SET_FAVORITE_IDS', [...state.favoriteIds, id]);
    }

    try {
      exists ? await servicesApi.deleteFavorite(id) : await servicesApi.addFavorite(id);
    } catch (e) {
      commit('SET_FAVORITE_IDS', backup);
      throw e;
    }
  },

  async createTicket({ dispatch }, { serviceId, data, files = [] }) {
    const attachments = [];
    if (files && files.length > 0) {
      const urls = await uploadFiles(files);
      files.forEach((file, idx) => {
        attachments.push({
          filename: file.name,
          contentSize: file.size,
          contentType: file.type || 'application/octet-stream',
          url: urls[idx],
        });
      });
    }

    let dynamicFields;
    if (data && data.dynamicFields) {
      dynamicFields = [];
      for (let item of data.dynamicFields) {
        if (item.inputType === DynamicFieldType.UPLOAD) {
          const urls = await uploadFiles(item.value);
          const newValue = item.value.map((file, idx) => ({
            filename: file.name,
            contentSize: file.size,
            contentType: file.type,
            url: urls[idx],
          }));
          item = { ...item, value: newValue };
        }
        dynamicFields.push(item);
      }
    }

    const res = await servicesApi.createTicket(serviceId, { ...data, dynamicFields, attachments });

    dispatch(
      'snackbar/notify',
      i18n.t('Заявка создана. В ближайшее время с вами свяжется наш менеджер.'),
      {
        root: true,
      }
    );

    return res;
  },

  async createTechSupportTicket(_, { data, files = [] }) {
    const attachments = [];
    if (files && files.length > 0) {
      const urls = await uploadFiles(files);
      files.forEach((file, idx) => {
        attachments.push({
          filename: file.name,
          contentSize: file.size,
          contentType: file.type || 'application/octet-stream',
          url: urls[idx],
        });
      });
    }

    return await servicesApi.createTechSupportTicket({ ...data, attachments });
  },
};

const mutations = {
  LOADED(state) {
    state.loaded = true;
  },

  SET_LOADING(state, payload) {
    state.loading = payload;
  },

  SET_CATEGORIES(state, payload) {
    state.categories = Object.freeze(payload);
  },

  SET_SERVICES(state, payload) {
    state.services = Object.freeze(payload);
  },

  SET_FAVORITE_IDS(state, payload) {
    state.favoriteIds = Object.freeze(payload);
  },

  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach(key => {
      state[key] = newState[key];
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
