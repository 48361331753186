export default [
  {
    path: '/admin/rent',
    name: 'admin.rent',
    components: {
      default: () => import(/* webpackChunkName: "admin" */ '@/views/admin/rent/RoutePage.vue'),
      tabs: () => import(/* webpackChunkName: "admin" */ '@/views/admin/rent/RouteTabs.vue'),
      search: () =>
        import(/* webpackChunkName: "admin" */ '@/views/admin/rent/bookings/BookingSearch'),
    },
    children: [
      {
        path: '/admin/rent/bookings',
        name: 'admin.rent.bookings',
        component: () =>
          import(/* webpackChunkName: "admin" */ '@/views/admin/rent/bookings/BookingList'),
        meta: { isAuth: true },
      },
      {
        path: '/admin/rent/booking-calendar',
        name: 'admin.rent.booking-calendar',
        component: () =>
          import(
            /* webpackChunkName: "admin" */ '@/views/admin/rent/booking-calendar/BookingCalendar'
          ),
        meta: { isAuth: true },
      },
    ],
  },
  {
    path: '/admin/rent/bookings/:id(\\d+)/view',
    name: 'admin.rent.bookings.view',
    component: () =>
      import(/* webpackChunkName: "admin" */ '@/views/admin/rent/bookings/BookingViewPage'),
    props: route => ({ id: parseInt(route.params.id, 10) }),
    meta: { isAuth: true, backBtn: true },
  },
  {
    path: '/admin/rent/bookings/:id(\\d+)/edit',
    name: 'admin.rent.bookings.edit',
    component: () =>
      import(/* webpackChunkName: "admin" */ '@/views/admin/rent/bookings/BookingFormPage'),
    props: route => ({ id: parseInt(route.params.id, 10) }),
    meta: { isAuth: true, backBtn: true },
  },
  {
    path: '/admin/rent/bookings/new',
    name: 'admin.rent.bookings.new',
    component: () =>
      import(/* webpackChunkName: "admin" */ '@/views/admin/rent/bookings/BookingFormPage'),
    meta: { isAuth: true, backBtn: true },
  },
];
