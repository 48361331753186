import eventApi from '@/api/eventApi';

const initialState = () => ({
  tags: [],
});

const state = initialState();

const getters = {
  tags: state => state.tags,
};

const actions = {
  async fetchTags({ commit }) {
    return eventApi.fetchTags({ limit: 1000 }).then(res => commit('SET_TAGS', res.data));
  },
};

const mutations = {
  SET_TAGS(state, payload) {
    state.tags = payload;
  },

  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach(key => {
      state[key] = newState[key];
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
